import React from 'react';
import { EditableText } from './TextEditor';
import CustomizedButton from '@/components/WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/Button';
import { MOBILE_DEVICE, TEXT_ALIGNMENT_POSITIONS } from '@/components/WidgetMaker/utils/constant';
import { getButtonVariantsData } from '../../Carousel/utils';
import { btnActionHandler } from '@/components/DesignSystem/ModernTheme/utils/btnUtils';
import { twMerge } from 'tailwind-merge';
import {
  BUTTON_SIZE_TYPE,
  BUTTON_TYPE,
} from 'src/components/WidgetMaker/utils/buttonConstant';
import { useGetDeviceType } from 'src/hooks/useGetDeviceType';
import { isMobileDevice } from 'src/components/WidgetMaker/WidgetDnD/isMobileDevice';

export function TextWidgetSection({ data, widgetOptions, currentIndex, widgetId}) {
  const { deviceType } = useGetDeviceType();
  const isMobile = deviceType === MOBILE_DEVICE;
  const sectionConfig = widgetOptions?.textSection;
  const textGroupItem = data;

  function getSubtitleSize(): string {
    switch (textGroupItem?.subtitleSize) {
      case BUTTON_SIZE_TYPE.SMALL:
        return '!tw-text-[10px]';
      case BUTTON_SIZE_TYPE.MEDIUM:
        return '!tw-text-[12px]';
      case BUTTON_SIZE_TYPE.LARGE:
        return '!tw-text-[14px]';
      default:
        return '!tw-text-[10px]';
    }
  }

  return (
    <div
      style={{
        textAlign:
          TEXT_ALIGNMENT_POSITIONS?.[
            isMobile ? sectionConfig?.mobileAlignment : sectionConfig?.alignment
          ],
      }}
      className={`tw-relative tw-z-[100] tw-flex tw-w-full tw-flex-col ${
        isMobile
          ? 'tw-justify-center'
          : `tw-justify-${sectionConfig?.position}`
      }`}
    >
      <div
        className={twMerge(
          `tw-relative tw-flex tw-flex-col`,
          textGroupItem?.subtitlePosition === 'bottom'
            ? 'tw-flex-col-reverse'
            : ' tw-gap-[12px]'
        )}
      >
        {textGroupItem?.subtitle?.replace('<p></p>', '') ? (
          <EditableText
            content={textGroupItem?.subtitle}
            className={twMerge(
              'tw-font-normal tw-leading-[20px]',
              getSubtitleSize(),
              `${textGroupItem?.subtitlePosition === 'bottom' && textGroupItem?.button[0]?.isShow ? 'tw-mt-[24px]' : ''}`
            )}
          />
        ) : null}
        <div>
          {textGroupItem?.title ? (
            <EditableText
              content={textGroupItem?.title}
              className="tw-font-normal tw-leading-[41px]"
            />
          ) : null}
          {textGroupItem?.description ? (
            <EditableText
              content={textGroupItem?.description}
              className="tw-mt-[16px] tw-text-[16px] tw-font-normal tw-leading-[1.4]"
            />
          ) : null}
          {(textGroupItem?.button?.length && textGroupItem?.button[0]?.isShow) && (
            <div className="tw-mt-[28px]">
              {textGroupItem?.button?.length ? (
                <div
                  className={`tw-flex tw-w-full`}
                  style={{
                    justifyContent:
                      TEXT_ALIGNMENT_POSITIONS?.[
                        isMobile
                          ? sectionConfig?.mobileAlignment
                          : sectionConfig?.alignment
                      ],
                  }}
                >
                  {textGroupItem?.button?.map((buttonData, btnIndex) => {
                    const buttonVariant = getButtonVariantsData(buttonData);
                    const selectedButtonType = buttonVariant[buttonData.type];
                    return buttonData.isShow ? (
                      <CustomizedButton
                        key={btnIndex}
                        size={BUTTON_SIZE_TYPE.LARGE}
                        btnType={buttonData.type}
                        buttonData={buttonData}
                        selectedButtonData={selectedButtonType}
                        variant={selectedButtonType.variant}
                        onClick={() => btnActionHandler(buttonData?.actions, () => {})}
                        className={twMerge(
                          `!tw-w-auto tw-rounded-[0px]  ${buttonData.type === BUTTON_TYPE.TEXTUAL ? '!tw-px-[0px]' : '!tw-px-[36px]'} !tw-pb-[12px] !tw-pt-[12px] !tw-text-[14px] !tw-font-[500] !tw-leading-[20px] tw-text-newBlack`
                        )}
                      >
                        {buttonData?.text}
                      </CustomizedButton>
                    ) : null;
                  })}
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function TextWidgetContainer({
  widgetOptions,
  widgetId
}) {
  const isMobile = isMobileDevice();
  const baseConfig = widgetOptions?.baseConfig;

  function getTextContainerStyle() {
    const sectionConfig = widgetOptions?.textSection;
    return {
      width: isMobile  ? ''  : baseConfig?.makeFullWidth ? '100%' : 'calc(70% + 95px)',
      color: sectionConfig.textColor,
    };
  }
  return (
    <div
      style={getTextContainerStyle() as any}
      className={`tw-relative tw-w-full
       ${isMobile ? 'tw-justify-center' : `tw-flex tw-flex-col`}
       ${baseConfig?.makeFullWidth ? 'tw-max-w-[1000px]' : ''} 
       `}
    >
      <div className="tw-flex tw-w-[100%] tw-flex-col">
        {widgetOptions?.textGroupSection?.groupItemsList?.map((data, index) => {
          if (data.isVisible === false) {
            return null;
          }
          return (
            <TextWidgetSection
              key={index}
              data={data}
              widgetOptions={widgetOptions}
              currentIndex = {0}
              widgetId = {widgetId}
            />
          );
        })}
      </div>
    </div>
  )
}